<template>
  <div class="flexC f-404">
    <a-result status="404" title="404" sub-title="Sorry, the page you visited does not exist.">
      <template #extra>
        <a-button type="primary" @click="back">Back Home</a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
  import { useRouter } from 'vue-router'

  export default {
    name: 'login',
    setup() {
      const router = useRouter()

      function back() {
        router.push('/')
      }

      return {
        back
      }
    }
  }
</script>

<style scoped>
  .f-404 {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
</style>
